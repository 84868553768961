import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { db } from '../config/firebaseConfig'
import { useLocation } from 'wouter'
import { v4 as uuidv4 } from 'uuid'
import {
    getLastThreeMonths,
    getLastThreeYears,
    industryCategories,
} from '../utils/helpers'

import { useFormValidation } from '../hooks/useFormValidation'

// Usage of the helper functions
const lastThreeMonths = getLastThreeMonths()
const lastThreeYears = getLastThreeYears()

const bank1 = `bankStatement${lastThreeMonths[0].monthName}${lastThreeMonths[0].year}`
const bank2 = `bankStatement${lastThreeMonths[1].monthName}${lastThreeMonths[1].year}`
const bank3 = `bankStatement${lastThreeMonths[2].monthName}${lastThreeMonths[2].year}`

const initialStyles = {
    businessName: {},
    dba: {},
    referrer: {},
    referrerEmail: {},
    useOfFunds: {},
    address: {},
    city: {},
    state: {},
    zip: {},
    tel: {},
    dateIncorporated: {},
    industry: {},
    businessDescription: {},
    ownerName: {},
    ownerTel: {},
    ownerEmail: {},
    ownerDOB: {},
    ownerSSN: {},
    pctOwnership: {},
    driversLicenseFront: {},
    bankStatement1: {},
    bankStatement2: {},
    bankStatement3: {},
    loanApplication: {},
    taxReturn1: {},
    taxReturn2: {},
    personalTaxReturn1: {},
    [bank1]: {},
    [bank2]: {},
    [bank3]: {},
    // bankStatementsAdditional: {},
    // applicationsAdditional: {},
    // taxReturnsAdditional: {},
    // miscellaneousAdditional: {},
}

const CreateDealPage = ({ user, userInfo }) => {
    const initialFormData = {
        referrer: 'John Doe',
        referrerEmail: 'test@example.com',
        useOfFunds: 'Working capital and expansion',
        businessName: 'Doe Enterprises LLC',
        dba: 'Doe Trading',
        address: '123 Main St',
        city: 'New York',
        state: 'NY',
        zip: '10001',
        tel: '5551234567',
        dateIncorporated: '2010-05-15',
        industry: 'Retail',
        businessDescription: 'A retail company specializing in consumer goods.',
        ownerName: 'John Doe',
        ownerTel: '5559876543',
        ownerEmail: 'owner@example.com',
        ownerDOB: '1980-07-10',
        ownerSSN: '123456789',
        pctOwnership: '100',

        dealId: uuidv4(),
        // referrer: '',
        // referrerEmail: '',
        // useOfFunds: '',
        // businessName: '',
        // dba: '',
        // address: '',
        // city: '',
        // state: '',
        // zip: '',
        // tel: '',
        // dateIncorporated: '',
        // industry: '',
        // businessDescription: '',
        // ownerName: '',
        // ownerTel: '',
        // ownerEmail: '',
        // ownerDOB: '',
        // ownerSSN: '',
        // pctOwnership: '',

        hasLargeLenderBalances: 'no',
        driversLicenseFront: '',
        bankStatement1: '',
        bankStatement2: '',
        bankStatement3: '',
        bankStatement4: '',
        loanApplication: '',
        plBusinessStatement: '',
        microSbaApplication: '',
        taxReturn1: '',
        taxReturn2: '',
        personalTaxReturn1: '',
        [bank1]: '',
        [bank2]: '',
        [bank3]: '',
        bankStatementsAdditional: [],
        applicationsAdditional: [],
        taxReturnsAdditional: [],
        miscellaneousAdditional: [],
    }

    const invalidStyle = { outline: '2px solid red' } // Red outline for invalid fields
    const validStyle = { outline: '2px solid #078586' } // Green outline for valid fields
    const [formData, setFormData] = useState(initialFormData)
    const [uploadingStates, setUploadingStates] = useState({})
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fieldStyles, setFieldStyles] = useState(initialStyles)
    const [submitStatusMessage, setSubmitStatusMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [, navigate] = useLocation()
    const [uploadedFiles, setUploadedFiles] = useState({})

    const [isFormValid, setIsFormValid] = useState(false)

    const { areAllFieldsFilled, validateField } = useFormValidation(
        formData,
        isCheckboxChecked
    )

    const MAX_FILE_SIZE = 50 * 1024 * 1024 // 50MB in bytes

    // Update validation on form data changes
    useEffect(() => {
        setIsFormValid(areAllFieldsFilled())
        // eslint-disable-next-line
    }, [formData, isCheckboxChecked])

    // Update field styles when validation state changes
    useEffect(() => {
        Object.keys(formData).forEach((key) => {
            if (!key.includes('Additional')) {
                const isValid = validateField(key, formData[key])
                setFieldStyles((prev) => ({
                    ...prev,
                    [key]: isValid ? validStyle : invalidStyle,
                }))
            }
        })
        // eslint-disable-next-line
    }, [formData])

    const handleChange = (e) => {
        const { name, value, files } = e.target

        if (files) {
            setFormData((prevState) => ({
                ...prevState,
                [name]: files[0],
            }))
            validateField(name, files[0])
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }))
            validateField(name, value)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!isFormValid) return

        try {
            setLoading(true)
            setSubmitStatusMessage('Submitting records...')

            const docId = formData.dealId
            const submissionData = {
                ...formData,
                docId,
                userId: userInfo.userId,
                userEmail: user.email,
                dateSubmitted: serverTimestamp(),
                status: 'Submitted',
                files: uploadedFiles,
            }

            await setDoc(
                doc(db, process.env.REACT_APP_DEALS_DB_REF, docId),
                submissionData
            )

            const response = await axios.post(
                'https://us-central1-quidity-app.cloudfunctions.net/createNewDeal',
                // 'http://127.0.0.1:5001/quidity-app/us-central1/createNewDeal',
                {
                    formData: submissionData,
                    userId: user.uid,
                    userEmail: user.email,
                    userInfo,
                    docId,
                    ref: process.env.REACT_APP_CREATE_DEAL_REF,
                }
            )

            setSubmitStatusMessage(response.data.message)
            navigate('/portal')
        } catch (error) {
            console.error('Error submitting form:', error)
            setErrorMessage(error.message)
        } finally {
            setLoading(false)
        }
    }

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!file || !(file instanceof Blob)) {
                // If the file is not provided or not a Blob, return 'N/A'
                resolve('N/A')
                return
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result.split(',')[1])
            reader.onerror = (error) => reject(error)
        })
    }

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailPattern.test(email)
    }

    const handleBlur = (e) => {
        const { name, value } = e.target
        const isValid = validateField(name, value)
        setFieldStyles((prev) => ({
            ...prev,
            [name]: isValid ? validStyle : invalidStyle,
        }))
    }

    const handleFocus = (e) => {
        const { name } = e.target
        let updatedStyles = { ...initialStyles }

        if (name === 'referrerEmail' || name === 'ownerEmail') {
            updatedStyles[name] = validateEmail(formData?.[name])
                ? validStyle
                : invalidStyle
        }

        setFieldStyles((prevStyles) => ({
            ...prevStyles,
        }))
    }

    const handleFileUpload = async (files, fileName) => {
        try {
            setUploadingStates((prev) => ({ ...prev, [fileName]: true }))

            // Generate deal ID if not already exists
            if (!formData.dealId) {
                setFormData((prev) => ({
                    ...prev,
                    dealId: uuidv4(),
                }))
            }

            const validFiles = Array.from(files).filter((file) => {
                if (file.size > MAX_FILE_SIZE) {
                    alert(`File "${file.name}" exceeds 1MB size limit`)
                    return false
                }
                return true
            })

            if (validFiles.length === 0) {
                setUploadingStates((prev) => ({ ...prev, [fileName]: false }))
                return
            }

            const fileData = await Promise.all(
                validFiles.map(async (file) => ({
                    name: fileName,
                    content: await fileToBase64(file),
                    type: file.type,
                    originalName: file.name,
                }))
            )

            // Upload to Firebase Storage through backend
            const response = await axios.post(
                'https://us-central1-quidity-app.cloudfunctions.net/uploadFile',
                // 'http://127.0.0.1:5001/quidity-app/us-central1/uploadFile',
                {
                    file: fileData[0], // For single file upload
                    files: fileName.includes('Additional') ? fileData : null, // For multiple files
                    userId: userInfo.userId,
                    docId: formData.dealId,
                    fileCategory: fileName,
                    isUserRegistration: false,
                }
            )

            if (!fileName.includes('Additional')) {
                const fileInfo = {
                    timestamp: new Date().toISOString(),
                    url: response.data.fileUrl,
                    originalName: validFiles[0].name,
                }
                setFormData((prev) => ({
                    ...prev,
                    [fileName]: fileInfo,
                }))
                setUploadedFiles((prev) => ({
                    ...prev,
                    [fileName]: fileInfo,
                }))
            }
            // For multiple file uploads (Additional files)
            else {
                setFormData((prev) => ({
                    ...prev,
                    [fileName]: [
                        ...(prev[fileName] || []),
                        ...validFiles.map((file, index) => ({
                            url: response.data.fileUrl,
                            timestamp: response.data.timestamp,
                            originalName: validFiles[0].name,
                        })),
                    ],
                }))
            }

            const isValid = validateField(fileName, validFiles[0])
            setFieldStyles((prev) => ({
                ...prev,
                [fileName]: isValid ? validStyle : invalidStyle,
            }))
        } catch (error) {
            console.error('Error uploading file:', error)
            alert('File upload failed. Please try again.')
        } finally {
            setUploadingStates((prev) => ({ ...prev, [fileName]: false }))
        }
    }

    const DragAndDrop = ({ label, fileName, isMandatory = false }) => {
        const [isDragging, setIsDragging] = useState(false)
        const isUploading = uploadingStates[fileName]
        const isMultiple = fileName.includes('Additional')

        const handleRemoveFile = () => {
            setFormData((prev) => ({
                ...prev,
                [fileName]: isMultiple ? [] : '',
            }))
            setUploadedFiles((prev) => ({
                ...prev,
                [fileName]: null,
            }))
            setFieldStyles((prev) => ({
                ...prev,
                [fileName]: {},
            }))
        }

        const getBorderStyle = () => {
            if (fieldStyles[fileName]?.outline === '2px solid red') {
                return 'border-red-500 bg-red-50'
            }
            if (fieldStyles[fileName]?.outline === '2px solid #078586') {
                return 'border-green-500 bg-green-50'
            }
            if (isDragging) {
                return 'border-blue-400 bg-blue-100'
            }
            if (isUploading) {
                return 'border-yellow-400 bg-yellow-50'
            }
            return 'border-gray-300 hover:border-blue-300 hover:bg-blue-50'
        }

        const handleDrop = async (e) => {
            e.preventDefault()
            setIsDragging(false)
            await handleFileUpload(e.dataTransfer.files, fileName)
        }

        const handleFileChange = async (e) => {
            await handleFileUpload(e.target.files, fileName)
        }

        return (
            <div className='flex items-center gap-4 mb-4'>
                <div>
                    <label
                        htmlFor={fileName}
                        className={`w-[120px] flex justify-center items-center h-full cursor-pointer text-center p-4 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 transition-colors duration-200 ${
                            isUploading ? 'opacity-50 cursor-wait' : ''
                        }`}
                    >
                        {isUploading ? (
                            <div className='w-5 h-5 border-2 border-gray-500 border-t-transparent rounded-full animate-spin' />
                        ) : !isMultiple ? (
                            `+ Add File`
                        ) : (
                            `+ Add Files`
                        )}
                    </label>
                    <input
                        id={fileName}
                        type='file'
                        onChange={handleFileChange}
                        className='hidden'
                        disabled={isUploading}
                        multiple={isMultiple}
                    />
                </div>
                <div
                    className={`flex-1 border-2 border-dashed ${getBorderStyle()} p-4 rounded-md transition-colors duration-200 cursor-pointer relative`}
                    onDragOver={(e) => {
                        e.preventDefault()
                        setIsDragging(true)
                    }}
                    onDragLeave={() => setIsDragging(false)}
                    onDrop={handleDrop}
                >
                    <div>
                        {formData[fileName] && (
                            <div className={`${isMultiple ? 'mb-2' : ''}`}>
                                {!isMultiple && (
                                    <div className='flex items-center justify-between'>
                                        <p className='text-sm text-gray-700'>
                                            {formData[fileName].originalName ||
                                                'File uploaded'}
                                        </p>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleRemoveFile()
                                            }}
                                            className='text-red-500 hover:text-red-700 font-medium'
                                        >
                                            Remove
                                        </button>
                                    </div>
                                )}
                                {isMultiple &&
                                    Array.isArray(formData[fileName]) &&
                                    formData[fileName].map((file, index) => (
                                        <div
                                            key={index}
                                            className='flex items-center justify-between'
                                        >
                                            <p className='text-sm text-gray-700'>
                                                {file.originalName}
                                            </p>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleRemoveFile()
                                                }}
                                                className='text-red-500 hover:text-red-700 font-medium'
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    ))}
                            </div>
                        )}

                        {isMultiple ? (
                            <p className='text-sm text-gray-500'>
                                {isUploading
                                    ? 'Uploading...'
                                    : 'Drag & drop files here'}
                                {isMandatory && (
                                    <b className='text-red-600'>&nbsp;*</b>
                                )}
                            </p>
                        ) : (
                            !formData[fileName] && (
                                <p className='text-sm text-gray-500'>
                                    {isUploading
                                        ? 'Uploading...'
                                        : 'Drag & drop files here'}
                                    {isMandatory && (
                                        <b className='text-red-600'>&nbsp;*</b>
                                    )}
                                </p>
                            )
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        user && (
            <div
                className='bg-themeColorPrimary3 min-h-screen flex flex-col justify-center items-center'
                style={{ animation: 'bottom-top 500ms ease' }}
            >
                <div className='mt-[200px] md:mt-[150px] lg:mt-[150px] bg-white shadow-md rounded-xl px-8 pt-6 pb-8 w-[95%] md:w-[800px] lg:w-[800px] mb-[50px]'>
                    <h1 className='block w-full text-center text-grey-darker text-[28px] mb-6 font-bold text-themeColorPrimary2'>
                        Create Deal
                    </h1>
                    <form onSubmit={handleSubmit}>
                        <p className='text-lg font-medium mb-2 text-center'>
                            Who should we contact regarding this merchant?
                        </p>
                        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='address'
                                >
                                    Referrer *
                                </label>
                                <input
                                    style={fieldStyles.referrer}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    id='referrer'
                                    name='referrer'
                                    value={formData.referrer}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='referrerEmail'
                                >
                                    Referrer Email *
                                </label>
                                <input
                                    style={fieldStyles.referrerEmail}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    id='referrerEmail'
                                    name='referrerEmail'
                                    value={formData.referrerEmail}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='businessName'
                                >
                                    Business Name *
                                </label>
                                <input
                                    style={fieldStyles.businessName}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    id='businessName'
                                    name='businessName'
                                    value={formData.businessName}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>
                        </div>

                        <div className='mb-4 w-full'>
                            <label
                                className='block text-grey-darker text-sm font-bold mb-2'
                                htmlFor='address'
                            >
                                Company Address *
                            </label>
                            <input
                                style={fieldStyles.address}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                type='text'
                                id='address'
                                name='address'
                                value={formData.address}
                                onChange={handleChange}
                                className='themed-input'
                                required
                            />
                        </div>

                        <div className='grid grid-cols-3 gap-0 md:gap-4 lg:gap-4 mb-4'>
                            <input
                                style={fieldStyles.city}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                type='text'
                                placeholder='City *'
                                id='city'
                                name='city'
                                value={formData.city}
                                onChange={handleChange}
                                className='input input-bordered w-full col-span-1 themed-input'
                                required
                            />
                            <select
                                style={fieldStyles.state}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                id='state'
                                name='state'
                                value={formData.state}
                                onChange={handleChange}
                                className='input input-bordered w-full col-span-1 themed-input cursor-pointer'
                                required
                            >
                                <option value=''>State *</option>
                                <option value='AL'>AL</option>
                                <option value='AK'>AK</option>
                                <option value='AZ'>AZ</option>
                                <option value='AR'>AR</option>
                                <option value='CA'>CA</option>
                                <option value='CO'>CO</option>
                                <option value='CT'>CT</option>
                                <option value='DE'>DE</option>
                                <option value='FL'>FL</option>
                                <option value='GA'>GA</option>
                                <option value='HI'>HI</option>
                                <option value='ID'>ID</option>
                                <option value='IL'>IL</option>
                                <option value='IN'>IN</option>
                                <option value='IA'>IA</option>
                                <option value='KS'>KS</option>
                                <option value='KY'>KY</option>
                                <option value='LA'>LA</option>
                                <option value='ME'>ME</option>
                                <option value='MD'>MD</option>
                                <option value='MA'>MA</option>
                                <option value='MI'>MI</option>
                                <option value='MN'>MN</option>
                                <option value='MS'>MS</option>
                                <option value='MO'>MO</option>
                                <option value='MT'>MT</option>
                                <option value='NE'>NE</option>
                                <option value='NV'>NV</option>
                                <option value='NH'>NH</option>
                                <option value='NJ'>NJ</option>
                                <option value='NM'>NM</option>
                                <option value='NY'>NY</option>
                                <option value='NC'>NC</option>
                                <option value='ND'>ND</option>
                                <option value='OH'>OH</option>
                                <option value='OK'>OK</option>
                                <option value='OR'>OR</option>
                                <option value='PA'>PA</option>
                                <option value='RI'>RI</option>
                                <option value='SC'>SC</option>
                                <option value='SD'>SD</option>
                                <option value='TN'>TN</option>
                                <option value='TX'>TX</option>
                                <option value='UT'>UT</option>
                                <option value='VT'>VT</option>
                                <option value='VA'>VA</option>
                                <option value='WA'>WA</option>
                                <option value='WV'>WV</option>
                                <option value='WI'>WI</option>
                                <option value='WY'>WY</option>
                            </select>
                            <input
                                style={fieldStyles.zip}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                maxLength={5}
                                type='text'
                                placeholder='Zip Code *'
                                id='zip'
                                name='zip'
                                value={formData.zip}
                                onChange={handleChange}
                                className='input input-bordered w-full col-span-1 themed-input'
                                required
                            />
                        </div>

                        <div className='flex items-center flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='tel'
                                >
                                    Telephone *
                                </label>
                                <input
                                    style={fieldStyles.tel}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    maxLength={10}
                                    id='tel'
                                    name='tel'
                                    value={formData.tel}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>

                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='dateIncorporated'
                                >
                                    Date Incorporated *
                                </label>
                                <input
                                    style={fieldStyles.dateIncorporated}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='date'
                                    id='dateIncorporated'
                                    name='dateIncorporated'
                                    value={formData.dateIncorporated}
                                    onChange={handleChange}
                                    className='themed-input'
                                    max='9999-12-31'
                                    required
                                />
                            </div>
                        </div>

                        <div className='mb-4 w-full'>
                            <label
                                className='block text-grey-darker text-sm font-bold mb-2'
                                htmlFor='businessDescription'
                            >
                                Business Description *
                            </label>
                            <textarea
                                maxLength={250}
                                style={fieldStyles.businessDescription}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                id='businessDescription'
                                name='businessDescription'
                                value={formData.businessDescription}
                                onChange={handleChange}
                                className='themed-input'
                                required
                            />
                        </div>

                        <div className='mb-4 w-full'>
                            <label
                                className='block text-grey-darker text-sm font-bold mb-2'
                                htmlFor='useOfFunds'
                            >
                                Use of Funds *
                            </label>
                            <input
                                style={fieldStyles.useOfFunds}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                type='text'
                                id='useOfFunds'
                                name='useOfFunds'
                                value={formData.useOfFunds}
                                onChange={handleChange}
                                className='themed-input'
                                required
                            />
                        </div>

                        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='industry'
                                >
                                    Industry *
                                </label>
                                <select
                                    style={fieldStyles.industry}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    id='industry'
                                    name='industry'
                                    value={formData.industry}
                                    onChange={handleChange}
                                    className='themed-input cursor-pointer'
                                    required
                                >
                                    <option value=''>Select Industry</option>
                                    {Object.keys(industryCategories).map(
                                        (ind) => (
                                            <option key={ind} value={ind}>
                                                {ind}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>

                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='ownerTel'
                                >
                                    Business Owner Telephone *
                                </label>
                                <input
                                    style={fieldStyles.ownerTel}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    maxLength={10}
                                    id='ownerTel'
                                    name='ownerTel'
                                    value={formData.ownerTel}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='ownerName'
                                >
                                    Business Owner Name *
                                </label>
                                <input
                                    style={fieldStyles.ownerName}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    id='ownerName'
                                    name='ownerName'
                                    value={formData.ownerName}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>

                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='ownerEmail'
                                >
                                    Business Owner Email *
                                </label>
                                <input
                                    style={fieldStyles.ownerEmail}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='email'
                                    id='ownerEmail'
                                    name='ownerEmail'
                                    value={formData.ownerEmail}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='ownerDOB'
                                >
                                    Business Owner DOB *
                                </label>
                                <input
                                    style={fieldStyles.ownerDOB}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='date'
                                    id='ownerDOB'
                                    name='ownerDOB'
                                    value={formData.ownerDOB}
                                    onChange={handleChange}
                                    className='themed-input'
                                    max='9999-12-31'
                                    required
                                />
                            </div>

                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='ownerSSN'
                                >
                                    Business Owner SSN *
                                </label>
                                <input
                                    style={fieldStyles.ownerSSN}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    maxLength={9}
                                    id='ownerSSN'
                                    name='ownerSSN'
                                    value={formData.ownerSSN}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>

                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='pctOwnership'
                                >
                                    Percentage Ownership *
                                </label>
                                <select
                                    style={fieldStyles.pctOwnership}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    id='pctOwnership'
                                    name='pctOwnership'
                                    value={formData.pctOwnership}
                                    onChange={handleChange}
                                    className='themed-input cursor-pointer'
                                    required
                                >
                                    <option value=''>Select Percentage</option>
                                    <option value='10'>10%</option>
                                    <option value='20'>20%</option>
                                    <option value='30'>30%</option>
                                    <option value='40'>40%</option>
                                    <option value='50'>50%</option>
                                    <option value='60'>60%</option>
                                    <option value='70'>70%</option>
                                    <option value='80'>80%</option>
                                    <option value='90'>90%</option>
                                    <option value='100'>100%</option>
                                </select>
                            </div>
                        </div>

                        <div className='mt-4 mb-4 w-full'>
                            <label className='block text-grey-darker text-sm font-bold mb-2'>
                                Does applicant have existing lender balances
                                over $50,000? *
                            </label>
                            <div className='flex items-center space-x-4'>
                                <label className='flex items-center space-x-2 cursor-pointer'>
                                    <input
                                        type='radio'
                                        name='hasLargeLenderBalances'
                                        value='yes'
                                        checked={
                                            formData.hasLargeLenderBalances ===
                                            'yes'
                                        }
                                        onChange={handleChange}
                                        className='h-[50px] w-[20px] cursor-pointer'
                                        required
                                    />
                                    <span className='text-[14px] font-semibold'>
                                        Yes
                                    </span>
                                </label>
                                <label className='flex items-center space-x-2 cursor-pointer'>
                                    <input
                                        type='radio'
                                        name='hasLargeLenderBalances'
                                        value='no'
                                        checked={
                                            formData.hasLargeLenderBalances ===
                                            'no'
                                        }
                                        onChange={handleChange}
                                        className='h-[50px] w-[20px] cursor-pointer'
                                    />
                                    <span className='text-[14px] font-semibold'>
                                        No
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* File Upload Fields */}
                        <section className='mb-8'>
                            <h3 className='text-lg underline font-bold mb-4'>
                                Upload Required Documents:
                            </h3>
                            {/* Bank Statements */}
                            <h4 className='text-md font-bold mb-2 underline'>
                                Bank Statements
                            </h4>
                            {lastThreeMonths.map((monthData, index) => (
                                <div
                                    key={`bankStatement-${index}`}
                                    className='mb-4'
                                >
                                    <p className='text-sm font-bold mb-1'>{`${monthData.monthName} ${monthData.year} Bank Statement`}</p>
                                    <DragAndDrop
                                        label={`${monthData.monthName} ${monthData.year} Bank Statement`}
                                        fileName={`bankStatement${monthData.monthName}${monthData.year}`}
                                        isMandatory={true}
                                    />
                                </div>
                            ))}
                            <DragAndDrop
                                label={`Bank Statements Additional`}
                                fileName={`bankStatementsAdditional`}
                            />
                            {/* Applications */}
                            <h4 className='text-md font-bold mt-6 mb-2 underline'>
                                Applications
                            </h4>
                            <div className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Loan Application
                                </p>
                                <DragAndDrop
                                    label='Loan Application'
                                    fileName='loanApplication'
                                    isMandatory={true}
                                />
                            </div>
                            {/* <div className='mb-4'>
                                <p className="text-sm font-bold mb-1">Micro Loan Application</p>
                                <DragAndDrop
                                label="Micro Loan Application"
                                fileName="microSbaApplication"
                                />
                            </div> */}
                            {/* <div className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Quidity Librium Application
                                </p>
                                <DragAndDrop
                                    label='Quidity Librium SBA Application'
                                    fileName='quidityLibriumSBA'
                                />
                            </div>
                            <div className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Quidity Jumbo Loan Application
                                </p>
                                <DragAndDrop
                                    label='Quidity Jumbo Loan Application'
                                    fileName='quidityJumboLoan'
                                />
                            </div> */}
                            {/* Display Additional Applications */}
                            {formData.applicationsAdditional.map(
                                (file, index) => (
                                    <div key={index} className='mb-4'>
                                        <p className='text-sm text-gray-700'>
                                            {file.name}
                                        </p>
                                    </div>
                                )
                            )}
                            <DragAndDrop
                                label={`Applications Additional`}
                                fileName={`applicationsAdditional`}
                            />
                            {/* Tax Documents */}
                            <h3 className='text-lg underline font-bold mb-4'>
                                Tax Returns:
                            </h3>
                            {/* Business Tax Returns */}
                            <h4 className='text-md font-bold mb-2 underline'>
                                Business Tax Returns
                            </h4>
                            {lastThreeYears.map((year, index) => (
                                <div key={`taxReturn-${year}`} className='mb-4'>
                                    <p className='text-sm font-bold mb-1'>{`Business Tax Return ${year}`}</p>
                                    <DragAndDrop
                                        label={`Business Tax Return ${year}`}
                                        fileName={`taxReturn${year}`}
                                    />
                                </div>
                            ))}
                            <DragAndDrop
                                label='Tax Returns Additional'
                                fileName='taxReturnsAdditional'
                            />
                            {/* Personal Tax Returns */}
                            <h4 className='text-md font-bold mt-6 mb-2 underline'>
                                Personal Tax Returns
                            </h4>
                            {lastThreeYears.map((year, index) => (
                                <div
                                    key={`personalTaxReturn-${year}`}
                                    className='mb-4'
                                >
                                    <p className='text-sm font-bold mb-1'>{`Personal Tax Return ${year}`}</p>
                                    <DragAndDrop
                                        label={`Personal Tax Return ${year}`}
                                        fileName={`personalTaxReturn${year}`}
                                    />
                                </div>
                            ))}
                            <DragAndDrop
                                label='Tax Returns Additional'
                                fileName='taxReturnsAdditional'
                            />
                            {/* Tax Extensions */}
                            <h4 className='text-md font-bold mt-6 mb-2 underline'>
                                Tax Extensions
                            </h4>
                            <div className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Business Tax Extension{' '}
                                    {new Date().getFullYear() - 1}
                                </p>
                                <DragAndDrop
                                    label={`Business Tax Extension ${
                                        new Date().getFullYear() - 1
                                    }`}
                                    fileName={`businessTaxExtension${
                                        new Date().getFullYear() - 1
                                    }`}
                                />
                            </div>
                            <div className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Personal Tax Extension{' '}
                                    {new Date().getFullYear() - 1}
                                </p>
                                <DragAndDrop
                                    label={`Personal Tax Extension ${
                                        new Date().getFullYear() - 1
                                    }`}
                                    fileName={`personalTaxExtension${
                                        new Date().getFullYear() - 1
                                    }`}
                                />
                            </div>
                            <DragAndDrop
                                label='Tax Extensions Additional'
                                fileName='taxExtensionsAdditional'
                            />
                            {/* Display Additional Tax Returns */}
                            {formData.taxReturnsAdditional.map(
                                (file, index) => (
                                    <div key={index} className='mb-4'>
                                        <p className='text-sm text-gray-700'>
                                            {file.name}
                                        </p>
                                    </div>
                                )
                            )}
                            {/* Miscellaneous Documents */}
                            <h4 className='text-md font-bold mt-6 mb-2 underline'>
                                Miscellaneous Documents
                            </h4>
                            <div className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Driver's License Front
                                </p>
                                <DragAndDrop
                                    label="Driver's License Front"
                                    fileName='driversLicenseFront'
                                    isMandatory={true}
                                />
                            </div>
                            <div
                                key={`plStatement-${
                                    new Date().getFullYear() - 1
                                }`}
                                className='mb-4'
                            >
                                <p className='text-sm font-bold mb-1'>{`P & L Statement ${
                                    new Date().getFullYear() - 1
                                }`}</p>
                                <DragAndDrop
                                    label={`P & L Statement ${
                                        new Date().getFullYear() - 1
                                    }`}
                                    fileName={`plStatement${
                                        new Date().getFullYear() - 1
                                    }`}
                                />
                            </div>
                            <div
                                key={`accountsReceivable-${
                                    new Date().getFullYear() - 1
                                }`}
                                className='mb-4'
                            >
                                <p className='text-sm font-bold mb-1'>{`Accounts Receivable ${
                                    new Date().getFullYear() - 1
                                }`}</p>
                                <DragAndDrop
                                    label={`Accounts Receivable ${
                                        new Date().getFullYear() - 1
                                    }`}
                                    fileName={`accountsReceivable${
                                        new Date().getFullYear() - 1
                                    }`}
                                />
                            </div>
                            <div
                                key={`accountsPayables-${
                                    new Date().getFullYear() - 1
                                }`}
                                className='mb-4'
                            >
                                <p className='text-sm font-bold mb-1'>{`Accounts Payables ${
                                    new Date().getFullYear() - 1
                                }`}</p>
                                <DragAndDrop
                                    label={`Accounts Payables ${
                                        new Date().getFullYear() - 1
                                    }`}
                                    fileName={`accountsPayables${
                                        new Date().getFullYear() - 1
                                    }`}
                                />
                            </div>
                            {/* Display Additional Miscellaneous Documents */}
                            {formData.miscellaneousAdditional.map(
                                (file, index) => (
                                    <div key={index} className='mb-4'>
                                        <p className='text-sm text-gray-700'>
                                            {file.name}
                                        </p>
                                    </div>
                                )
                            )}
                            <DragAndDrop
                                label={`Miscellaneous Additional`}
                                fileName={`miscellaneousAdditional`}
                            />
                        </section>

                        <div className='mt-2 mb-2'>
                            <input
                                id='checkbox-create-deal'
                                type='checkbox'
                                className='mr-2 cursor-pointer scale-[1.2]'
                                value={isCheckboxChecked}
                                onChange={() =>
                                    setIsCheckboxChecked(!isCheckboxChecked)
                                }
                            />
                            <label
                                className='cursor-pointer text-sm'
                                htmlFor='checkbox-create-deal'
                            >
                                The above aforementioned (“Referrer”) refers
                                clients for Quidity, LLC's loan programs and
                                certifies authority to submit applications. The
                                Referrer confirms all information and referrals
                                are accurate and truthful. Referrer authorizes
                                Quidity, LLC, and affiliates to (a) Obtain
                                reports about the merchant, (b) Access the
                                merchant's financial statements, (c) Share
                                information with authorized parties, (d) Receive
                                information from banks/creditors, (e) Contact
                                the merchant via text, call, or email. The
                                referrer is responsible for information
                                accuracy. This authorization lasts through the
                                loan process and term unless revoked. Quidity,
                                LLC will use the information solely for loan
                                evaluation and servicing, complying with
                                applicable laws.
                            </label>
                        </div>
                        {(!isFormValid || !isCheckboxChecked) && (
                            <p className='mt-4 font-bold text-red-500'>
                                Please complete all required fields
                            </p>
                        )}
                        <div className='flex items-center justify-between'>
                            <button
                                type='submit'
                                className='mt-4 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded disabled:opacity-[50%] disabled:hover:bg- disabled:bg-gray-600'
                                // disabled={
                                //     !isFormValid || loading || errorMessage
                                // }
                            >
                                {!loading ? (
                                    'Create Deal'
                                ) : (
                                    <div className='flex items-center'>
                                        <div className='button-loader mr-2'></div>
                                        Please wait
                                    </div>
                                )}
                            </button>
                        </div>
                        <p className='mt-4 font-bold text-themeColorPrimary2'>
                            {loading && submitStatusMessage}
                        </p>
                    </form>
                    <p className='mt-4 font-bold text-red-500'>
                        {errorMessage}
                    </p>
                </div>
            </div>
        )
    )
}

export default CreateDealPage
