import React, { useState, useEffect } from 'react'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../config/firebaseConfig'
import Loading from '../components/Loading'
import moment from 'moment'
import { MessageSquare, X } from 'lucide-react'

const formatTimestamp = (timestamp) => {
    try {
        const date = new Date(timestamp)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    } catch (error) {
        return 'Invalid date'
    }
}

const PortalPage = ({ user, setSelectedDeal, setDetailModalOpen }) => {
    const [deals, setDeals] = useState([])
    const [loading, setLoading] = useState(true)
    const [messages, setMessages] = useState([])
    const [selectedMessages, setSelectedMessages] = useState(null)
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
    const [activeMessageTab, setActiveMessageTab] = useState('unread')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const fetchDeals = async () => {
            const dealsQuery = collection(
                db,
                process.env.REACT_APP_DEALS_DB_REF
            )
            const querySnapshot = await getDocs(dealsQuery)
            const dealsData = querySnapshot.docs
                .map((doc) => ({ id: doc.id, ...doc.data() }))
                .filter((doc) => doc.userEmail === user?.email)
                .sort(
                    (a, b) =>
                        b.dateSubmitted.toDate() - a.dateSubmitted.toDate()
                )

            setDeals(dealsData)
            setLoading(false)
        }

        fetchDeals()
    }, [user])

    const handleViewDeal = (deal) => {
        window.location = `/deal/modify/${deal.id}`
        setSelectedDeal(deal)
    }

    const handleViewMessages = (deal) => {
        const dealMessages = messages.filter((msg) => msg.dealId === deal.id)
        setSelectedMessages({ deal, messages: dealMessages })
        setIsMessageModalOpen(true)
    }

    const MessageModal = () => {
        if (!isMessageModalOpen || !selectedMessages) return null

        const { deal, messages: dealMessages } = selectedMessages
        const unreadMessages = dealMessages.filter((msg) => !msg.read)
        const readMessages = dealMessages.filter((msg) => msg.read)
        const displayMessages =
            activeMessageTab === 'unread' ? unreadMessages : readMessages

        return (
            <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]'>
                <div className='bg-white rounded-lg w-full max-w-2xl mx-4'>
                    <div className='flex justify-between items-center p-4 border-b border-gray-200'>
                        <h2 className='text-xl font-bold'>
                            Messages for {deal.businessName}
                        </h2>
                        <button
                            onClick={() => setIsMessageModalOpen(false)}
                            className='text-gray-500 hover:text-gray-700'
                        >
                            <X className='h-6 w-6' />
                        </button>
                    </div>

                    <div className='flex border-b border-gray-200'>
                        <button
                            className={`flex-1 py-2 px-4 text-sm font-medium ${
                                activeMessageTab === 'unread'
                                    ? 'text-themeColorPrimary border-b-2 border-themeColorPrimary'
                                    : 'text-gray-500 hover:text-gray-700'
                            }`}
                            onClick={() => setActiveMessageTab('unread')}
                        >
                            Unread ({unreadMessages.length})
                        </button>
                        <button
                            className={`flex-1 py-2 px-4 text-sm font-medium ${
                                activeMessageTab === 'read'
                                    ? 'text-themeColorPrimary border-b-2 border-themeColorPrimary'
                                    : 'text-gray-500 hover:text-gray-700'
                            }`}
                            onClick={() => setActiveMessageTab('read')}
                        >
                            Read ({readMessages.length})
                        </button>
                    </div>

                    <div className='max-h-[400px] overflow-y-auto p-4'>
                        {displayMessages.length > 0 ? (
                            <div className='space-y-4'>
                                {displayMessages
                                    .sort((a, b) => b.timestamp - a.timestamp)
                                    .map((message, index) => (
                                        <div
                                            key={index}
                                            className={`p-4 rounded-lg border ${
                                                message.read
                                                    ? 'bg-gray-50'
                                                    : 'bg-white border-blue-200'
                                            }`}
                                        >
                                            <div className='flex justify-between items-start mb-2'>
                                                <span className='font-medium'>
                                                    {message.read
                                                        ? 'Read'
                                                        : 'Unread'}
                                                </span>
                                                <span className='text-sm text-gray-500'>
                                                    {formatTimestamp(
                                                        message.timestamp
                                                    )}
                                                </span>
                                            </div>
                                            <p className='text-gray-800'>
                                                {message.message}
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            <div className='text-center text-gray-500 py-8'>
                                No {activeMessageTab} messages for this deal
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    if (loading) {
        return (
            <div className='h-screen'>
                <Loading theme='primary' />
            </div>
        )
    }

    return (
        <div className='bg-themeColorPrimary3 min-h-full flex flex-col w-full px-6 pt-6'>
            <div className='mt-[200px] md:mt-[130px] lg:mt-[130px]'>
                <h1 className='text-4xl font-bold my-4 mb-[30px]'>
                    Your Deals
                </h1>
                {deals.length > 0 ? (
                    <table className='w-full px-6'>
                        <thead>
                            <tr className='text-center text-xl'>
                                <th className='pb-4'>Date Submitted</th>
                                <th className='pb-4'>Business</th>
                                <th className='pb-4'>Referrer</th>
                                <th className='pb-4'>Status</th>
                                <th className='pb-4'>Amount Approved</th>
                                <th className='pb-4'>Messages</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deals.map((deal) => (
                                <tr
                                    key={deal.id}
                                    className='mb-4 shadow-lg rounded-2xl border w-full hover:shadow-xl hover:border-themeColorPrimary transition-all'
                                >
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {moment(
                                            (
                                                deal?.dateSubmitted || moment()
                                            ).toDate()
                                        ).format('MM - DD - YYYY')}
                                    </td>
                                    <td
                                        className='text-base text-center mb-2 py-4 underline cursor-pointer font-bold'
                                        onClick={() => handleViewDeal(deal)}
                                    >
                                        {deal?.businessName}
                                    </td>
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {deal?.referrer}
                                    </td>
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {deal?.status}
                                    </td>
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {deal?.amountApproved
                                            ? `$${deal.amountApproved.toLocaleString()}`
                                            : '-'}
                                    </td>
                                    <td className='text-center py-4'>
                                        <div
                                            className='relative inline-flex items-center cursor-pointer'
                                            onClick={() =>
                                                handleViewMessages(deal)
                                            }
                                        >
                                            <MessageSquare className='h-5 w-5 text-gray-500 hover:text-gray-700' />
                                            {messages.filter(
                                                (msg) =>
                                                    msg.dealId === deal.id &&
                                                    !msg.read
                                            ).length > 0 && (
                                                <span className='absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center'>
                                                    {
                                                        messages.filter(
                                                            (msg) =>
                                                                msg.dealId ===
                                                                    deal.id &&
                                                                !msg.read
                                                        ).length
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>
                        <table className='w-full px-6'>
                            <thead>
                                <tr className='text-center text-xl'>
                                    <th className='pb-4'>Date Submitted</th>
                                    <th className='pb-4'>Business</th>
                                    <th className='pb-4'>Referrer</th>
                                    <th className='pb-4'>Status</th>
                                    <th className='pb-4'>Amount Approved</th>
                                    <th className='pb-4'>Messages</th>
                                </tr>
                            </thead>
                        </table>
                        <p className='w-full text-center mt-4 text-2xl font-bold text-red-600'>
                            No deals found!
                        </p>
                    </div>
                )}
            </div>

            {/* Message Modal */}
            <MessageModal />
        </div>
    )
}

export default PortalPage
